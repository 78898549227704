<!--
  -  Copyright (C) Healabs 2022 - All Rights Reserved
  -  Unauthorized copying of this file, via any medium is strictly prohibited
  -  Proprietary and confidential
  -
  -->

<template>
  <div class="mx-auto">
    <v-dialog :value="value" max-width="550" transition="dialog-bottom-transition"
              @input="value => $emit('input', value)"
    >
      <v-card class="py-5">
        <v-icon small class="close-submit-dialog" @click="$emit('input', false)">$close</v-icon>
        <div class="validate-submit-dialog primary-border">
          <div class="text-h5 font-weight-light primary--text px-5">
            Adresse e-mail associée à cette admission
          </div>
          <div class="text-caption font-alt mb-1 px-5">
            Les autres admissions du patient conserveront l'adresse e-mail actuellement définie.
          </div>
          <v-form ref="form" @submit.prevent="submit">
            <v-col cols="12">
              <text-field v-model="email" label="Email de l'utilisateur" rounded
                          :rules="[$rules.required, $rules.email, $rules.maxLength(200)]"
              />
            </v-col>

            <v-card-actions class="justify-end pb-0">
              <v-btn small text @click="$emit('input', false)">
                Annuler
              </v-btn>
              <v-btn small color="primary" type="submit" :loading="submitting" :disabled="submitting">
                Modifier
              </v-btn>
            </v-card-actions>
          </v-form>
        </div>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>

  import * as admissions from '@/modules/stays/api/admissions'
  import logger from '@/services/logger'
  import TextField from '@/modules/core/components/TextField'

  export default {
    name: 'EmailUpdateDialog',
    components: {TextField},
    props: {
      value: Boolean,
      stay: Object
    },
    data() {
      return {
        email: this.stay.patient.email,
        submitting: false
      }
    },
    methods: {
      async submit() {
        if (this.$refs.form.validate()) {
          try {
            this.submitting = true

            await admissions.update(this.stay.id, {
              patient: {
                email: this.email
              }
            }, true)

            this.$root.$emit('snackbar:open', {
              snackbar: () => import('@/modules/core/components/Snackbar'),
              type: 'success',
              title: 'Adresse e-mail du patient modifiée avec succès.'
            })

            this.$emit('change', this.email)
          } catch (error) {
            this.$root.$emit('snackbar:open', {
              snackbar: () => import('@/modules/core/components/Snackbar'),
              type: 'error',
              title: 'Une erreur est survenue'
            })
            logger.error(error)
          } finally {
            this.submitting = false
          }
        }

      }
    }
  }
</script>

<style scoped>

</style>
