<!--
  -  Copyright (C) Healabs 2022 - All Rights Reserved
  -  Unauthorized copying of this file, via any medium is strictly prohibited
  -  Proprietary and confidential
  -
  -->

<template>
  <v-container v-if="!loading">
    <v-row>
      <v-col cols="12" md="5">
        <v-card class="elevation-x py-3 pb-0">
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title class="text-h5 font-alt">Dossier d'admission</v-list-item-title>
              <v-list-item-subtitle class="font-alt font-weight-light text-caption">
                Informations et statut de l'admission
              </v-list-item-subtitle>
              <v-list-item-subtitle class="primary--text mt-2">
                <v-btn x-small color="primary" text @click="backToList">
                  <font-awesome-icon :icon="['fal','chevron-left']" class="mr-2" size="sm" />
                  Revenir à la liste
                </v-btn>
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
          <v-divider />
          <v-card-text class="py-0">
            <v-list-item three-line>
              <v-list-item-avatar size="48">
                <v-img src="@/assets/img/clinique.jpg" />
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title class="font-weight-light">{{ stay.service.establishment.label }}</v-list-item-title>
                <v-list-item-subtitle class="text-caption font-alt">
                  Le
                  <span class="font-weight-bold font-alt primary--text">
                    {{ $dayjs(stay.startDate).format('LLL') }}
                  </span>
                  (<v-btn x-small plain class="pa-0" @click.stop="startDateUpdateDialog = true">modifier</v-btn>)
                </v-list-item-subtitle>
                <v-list-item-subtitle class="text-caption font-alt">
                  {{ stay.service.label }} ({{ labelStayType(stay.type) }})
                  {{ stay.doctor ? ' avec ' + stay.doctor.firstname + ' ' + stay.doctor.lastname : '' }}
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-card-text>
          <v-divider />
          <v-card-text class="py-0">
            <v-list-item v-if="stay.status === 'in_progress'" class="pa-0">
              <template v-if="responses != null">
                <v-list-item-avatar size="64">
                  <v-progress-circular :rotate="270" :size="48" :width="3" :value="stay.completionRate"
                                       :color="completionToColor(stay.completionRate)" class="ml-4"
                  />
                </v-list-item-avatar>
                <v-list-item-content>
                  <v-list-item-title class="font-weight-light">
                    En cours -
                    <span :class="'font-weight-bold '+ completionToColor(stay.completionRate)+'--text'">
                      {{ stay.completionRate }}%
                    </span>
                  </v-list-item-title>
                  <v-list-item-subtitle class="text-caption font-alt">
                    Le patient est en cours de saisie
                  </v-list-item-subtitle>
                </v-list-item-content>
              </template>
              <template v-else>
                <v-list-item-avatar size="64">
                  <font-awesome-icon :icon="['fad','pause-circle']" size="3x" />
                </v-list-item-avatar>
                <v-list-item-content>
                  <v-list-item-title class="font-weight-light">En attente</v-list-item-title>
                  <v-list-item-subtitle class="text-caption font-alt">
                    Le patient doit remplir son dossier
                  </v-list-item-subtitle>
                </v-list-item-content>
              </template>
            </v-list-item>
            <v-list v-if="stay.status === 'complete'" class="pa-0">
              <v-list-item class="pa-0">
                <v-list-item-avatar size="64">
                  <font-awesome-icon :icon="['fad', 'check-circle']" size="3x" class="success--text" />
                </v-list-item-avatar>
                <v-list-item-content>
                  <v-list-item-title class="font-weight-light">Dossier complet</v-list-item-title>
                  <v-list-item-subtitle class="text-caption font-alt">
                    Le patient est prêt pour une admission
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </v-list>
            <v-list v-if="stay.status === 'validated'" class="pa-0">
              <v-list-item class="pa-0">
                <v-list-item-avatar size="64">
                  <font-awesome-icon :icon="['fad', 'check-circle']" size="3x" class="success--text" />
                </v-list-item-avatar>
                <v-list-item-content>
                  <v-list-item-title class="font-weight-light">Dossier validé</v-list-item-title>
                  <v-list-item-subtitle class="text-caption font-alt">
                    L'admission a été validée
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </v-list>
            <v-list v-if="stay.status === 'cancelled'" class="pa-0">
              <v-list-item class="pa-0">
                <v-list-item-avatar size="64">
                  <font-awesome-icon :icon="['fad', 'window-close']" size="3x" />
                </v-list-item-avatar>
                <v-list-item-content>
                  <v-list-item-title class="font-weight-light">Dossier annulé</v-list-item-title>
                  <v-list-item-subtitle class="text-caption font-alt">
                    L'admission a été annulée
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-card-text>
          <div class="d-flex justify-center mb-2">
            <v-btn v-if="stay.status === 'in_progress'" color="secondary" elevation="0" small class="mb-2"
                   @click="validateAdmissionDialog = true"
            >
              Valider le dossier
            </v-btn>
            <v-btn v-if="stay.status !== 'cancelled'" color="grey lighten-2" elevation="0" small class="mb-2 ml-3"
                   @click="cancelAdmissionDialog = true"
            >
              Annuler le dossier
            </v-btn>
            <v-btn v-if="stay.status === 'cancelled'" color="grey lighten-2" elevation="0" small class="mb-2 ml-3"
                   @click="reopenAdmissionDialog = true"
            >
              Réouvrir le dossier
            </v-btn>
          </div>
        </v-card>
        <v-card v-if="stay.status !== 'validated' && missingSurveyFields.length" class="elevation-x py-3 mt-5">
          <v-card-title class="pt-0 font-alt">Éléments manquants</v-card-title>
          <v-card-subtitle class="font-weight-light text-caption font-alt">
            Relancez le patient pour les informations requises
          </v-card-subtitle>
          <v-card-text class="py-0">
            <v-list v-if="stay.status === 'in_progress' && responses != null">
              <v-list-item v-for="(fieldResponse, index) in missingSurveyFields" :key="index" class="pa-0">
                <v-list-item-avatar size="16">
                  <font-awesome-icon
                    :icon="['fal', fieldResponse.field.type === 'document' ? 'file-medical' : 'chevron-right']"
                    size="2x"
                    :class="fieldResponse.field.type === 'document' ? 'orange--text' : 'success--text'"
                  />
                </v-list-item-avatar>
                <v-list-item-content>
                  <v-list-item-title class="text-subtitle-1 font-weight-regular op-60">
                    {{ fieldResponse.field.label }}
                  </v-list-item-title>
                  <v-list-item-subtitle class="text-caption font-alt">
                    {{ fieldResponse.field.type === 'document' ? 'Documents' : 'Informations générales' }}
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-card-text>
        </v-card>
        <v-card v-if="stay.status !== 'validated' && stay.status !== 'cancelled'" class="elevation-x py-3 mt-5">
          <v-card-title class="pt-0 font-alt">Relance du dossier</v-card-title>
          <v-card-subtitle class="font-weight-light text-caption font-alt">
            Retrouvez la liste des relances pour ce dossier
          </v-card-subtitle>
          <v-card-text class="py-0">
            <v-list>
              <template v-for="(reminder, index) in reminders">
                <v-list-item v-if="reminder.author" :key="index" class="pa-0">
                  <v-list-item-avatar size="16">
                    <font-awesome-icon :icon="['fad', 'bell-plus']" size="2x" class="grey--text" />
                  </v-list-item-avatar>
                  <v-list-item-content>
                    <v-list-item-title class="text-subtitle-1 font-weight-regular op-60">
                      Initiée par : <span class="primary--text">{{ reminder.author.identity.firstName }} {{ reminder.author.identity.lastName }}</span>
                    </v-list-item-title>
                    <v-list-item-subtitle class="text-caption font-alt">
                      Le {{ $dayjs(reminder.date).format('LLL') }}
                    </v-list-item-subtitle>
                    <v-list-item-subtitle v-if="reminder.comment" class="text-caption font-alt">
                      Commentaire: {{ reminder.comment }}
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </template>
            </v-list>
          </v-card-text>
          <v-card-actions class="px-4 py-0 flex-wrap">
            <reminder-form v-if="stay.status === 'in_progress'" :missing-survey-fields="missingSurveyFields"
                           :stay-id="stay['@id']" @add-reminder="addReminderItem"
            />
          </v-card-actions>
        </v-card>
      </v-col>
      <v-col cols="12" md="7">
        <v-card class="elevation-x">
          <v-list-item class="pt-2">
            <v-list-item-avatar size="58">
              <v-img src="@/assets/img/user-generic.png" />
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title class="text-h6 font-weight-medium">
                {{ stay.patient.firstName }} {{ stay.patient.lastName }}
              </v-list-item-title>
              <v-list-item-subtitle class="text-caption font-alt">
                Né(e) le {{ $dayjs(stay.patient.birthDate).format('DD MMMM YYYY') }}
              </v-list-item-subtitle>
              <v-list-item-subtitle class="text-caption font-alt">
                {{ stay.patient.email }}
                <v-btn v-if="changeEmailAllowed" x-small plain class="pa-0" @click.stop="patientEmailUpdateDialog = true">(modifier)</v-btn>
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-card>
        <informations :patient="stay.patient" :form="form"
                      :field-responses="responses ? responses.fieldResponses : null"
        />
      </v-col>
    </v-row>
    <v-dialog v-model="validateAdmissionDialog" max-width="530">
      <v-card class="color-dialog informations">
        <v-icon small class="close-submit-dialog" @click.stop="validateAdmissionDialog = false">$close</v-icon>
        <v-list-item class="px-3 py-2 pb-0">
          <v-img v-if="$vuetify.breakpoint.smAndUp" :src="require('@/assets/img/illustrations/validate-file.svg')"
                 max-width="150px"
          />
          <v-list-item-content class="ml-4 pl-4">
            <v-list-item-title class="text-h4 font-weight-light color-title">Valider le dossier</v-list-item-title>
            <v-list-item-subtitle class="subtitle-text text-body-2 font-alt mb-1 ml-1">
              Êtes vous sur de vouloir valider ce dossier?
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
        <v-card-actions class="pt-0 justify-end">
          <v-spacer />
          <v-btn small text @click="validateAdmissionDialog = !validateAdmissionDialog">Annuler</v-btn>
          <v-btn small color="secondary" :loading="modalLoading" text @click="validate">Confirmer</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="cancelAdmissionDialog" max-width="530">
      <v-card class="color-dialog  informations">
        <v-icon small class="close-submit-dialog" @click.stop="cancelAdmissionDialog = false">$close</v-icon>
        <v-list-item class="px-3 py-2 pb-0">
          <v-img v-if="$vuetify.breakpoint.smAndUp" :src="require('@/assets/img/illustrations/validate-file.svg')"
                 max-width="150px"
          />
          <v-list-item-content class="ml-4 pl-4">
            <v-list-item-title class="text-h4 font-weight-light color-title">Annuler le dossier</v-list-item-title>
            <v-list-item-subtitle class="subtitle-text text-body-2 font-alt mb-1 ml-1">
              Êtes vous sur de vouloir annuler ce dossier?
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
        <v-card-actions class="pt-0 justify-end">
          <v-spacer />
          <v-btn small text @click="cancelAdmissionDialog = !cancelAdmissionDialog">Annuler</v-btn>
          <v-btn small color="secondary" :loading="modalLoading" text @click="cancel">Confirmer</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="reopenAdmissionDialog" max-width="530">
      <v-card class="color-dialog  informations">
        <v-icon small class="close-submit-dialog" @click.stop="reopenAdmissionDialog = false">$close</v-icon>
        <v-list-item class="px-3 py-2 pb-0">
          <v-img v-if="$vuetify.breakpoint.smAndUp" :src="require('@/assets/img/illustrations/validate-file.svg')"
                 max-width="150px"
          />
          <v-list-item-content class="ml-4 pl-4">
            <v-list-item-title class="text-h4 font-weight-light color-title">Réouvrir le dossier</v-list-item-title>
            <v-list-item-subtitle class="subtitle-text text-body-2 font-alt mb-1 ml-1">
              Êtes vous sur de vouloir réouvrir ce dossier?
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
        <v-card-actions class="pt-0 justify-end">
          <v-spacer />
          <v-btn small text @click="reopenAdmissionDialog = !reopenAdmissionDialog">Annuler</v-btn>
          <v-btn small color="secondary" :loading="modalLoading" text @click="reopen">Confirmer</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <email-update-dialog v-model="patientEmailUpdateDialog" :stay="stay" @change="onPatientEmailChange" />
    <start-date-update-dialog v-model="startDateUpdateDialog" :stay="stay" @change="onStartDateChange" />
    <modal v-model="validateSuccessDialog" title="Dossier validé" color="color-dialog validate"
           subtitle="Le dossier du patient est désormais validé"
           :img="require('@/assets/img/illustrations/validate-file-2.svg')" :actions="false"
    />
    <modal v-model="cancelSuccessDialog" title="Dossier annulé" color="color-dialog validate"
           subtitle="Le dossier du patient est désormais annulé"
           :img="require('@/assets/img/illustrations/validate-file-2.svg')" :actions="false"
    />
    <modal v-model="reopenSuccessDialog" title="Dossier ouvert" color="color-dialog validate"
           subtitle="Le dossier du patient est désormais ouvert"
           :img="require('@/assets/img/illustrations/validate-file-2.svg')" :actions="false"
    />
  </v-container>
  <loader v-else />
</template>


<script>
  import Loader from '@/modules/core/components/Loader'
  import Modal from '@/modules/core/components/Modal'
  import professionals from '@/modules/professionals/api'
  import * as admissions from '@/modules/stays/api/admissions'
  import Informations from '@/modules/professionals/components/profil/Informations'
  import ReminderForm from '@/modules/professionals/components/profil/ReminderForm'
  import dataDisplayers from '@/mixins/dataDisplayers'
  import { empty } from '@/utils/variables'
  import * as reminders from '@/modules/forms/api/reminders'
  import api from '@/services/api'
  import EmailUpdateDialog from '@/modules/stays/components/EmailUpdateDialog'
  import StartDateUpdateDialog from '@/modules/stays/components/StartDateUpdateDialog'

  export default {
    name: 'AdmissionCard',
    components: {StartDateUpdateDialog, EmailUpdateDialog, Informations, ReminderForm, Modal, Loader},
    mixins: [dataDisplayers],
    data() {
      return {
        validateSuccessDialog: false,
        validateAdmissionDialog: false,
        cancelSuccessDialog: false,
        cancelAdmissionDialog: false,
        reopenSuccessDialog: false,
        reopenAdmissionDialog: false,
        followPatientDialog: false,
        startDateUpdateDialog: false,
        patientEmailUpdateDialog: false,
        modalLoading: false,
        newReminder: false,
        loading: true,
        responses: null,
        form: null,
        stay: null,
        reminders: null,
        changeEmailAllowed: false
      }
    },
    computed: {
      today() {
        return this.$dayjs(new Date())
      },
      stayDate() {
        return this.$dayjs(this.stay.startDate)
      },
      missingSurveyFields() {
        return this.stay && this.responses ? this.responses.fieldResponses
          .filter(fieldResponse => fieldResponse.field.required && empty(fieldResponse.response) && empty(fieldResponse.media)) : []
      },
      isArchived() {
        return (this.stay.status === 'validated' || this.stayDate < this.today) && this.stay.status !== 'cancelled'
      }
    },
    async created() {
      this.stay = (await admissions.get(this.$route.params.id)).data
      this.form = (await api.get(this.stay.formId)).data
      if(this.stay.formResponseId){
        this.responses = (await api.get(this.stay.formResponseId)).data
      }

      this.loading = false
      await this.getRemindersList()
      if (this.stay.status === 'in_progress' && this.$dayjs(this.stay.startDate) > this.today) {
        this.changeEmailAllowed = true
      }
    },
    methods: {
      backToList() {
        let routeName = 'admissions-pending'
        if (this.isArchived) {
          routeName = 'admissions-archived'
        } else if(this.stay.status === 'cancelled') {
          routeName = 'admissions-cancelled'
        }

        this.$router.push({name: routeName})
      },
      onPatientEmailChange(email) {
        this.stay.patient.email = email
        this.patientEmailUpdateDialog = false
      },
      onStartDateChange(startDate) {
        this.stay.startDate = startDate
        this.startDateUpdateDialog = false
      },
      async validate() {
        this.modalLoading = true
        try {
          await professionals.validateStay(this.stay.id)
          this.stay.status = 'validated'
          this.validateAdmissionDialog = false
          this.validateSuccessDialog = true

        } catch (error) {
          this.$root.$emit('error', error)
        } finally {
          this.modalLoading = false
        }
      },
      async cancel() {
        this.modalLoading = true
        try {
          await professionals.cancelStay(this.stay.id)
          this.stay.status = 'cancelled'
          this.cancelAdmissionDialog = false
          this.cancelSuccessDialog = true

        } catch (error) {
          this.$root.$emit('error', error)
        } finally {
          this.modalLoading = false
        }
      },
      async reopen() {
        this.modalLoading = true
        try {
          await professionals.reopenStay(this.stay.id)
          this.stay.status = 'in_progress'
          this.reopenAdmissionDialog = false
          this.reopenSuccessDialog = true

        } catch (error) {
          this.$root.$emit('error', error)
        } finally {
          this.modalLoading = false
        }
      },
      async getRemindersList() {
        this.newReminder = true
        try {
          await reminders.list({params: {
            stay: this.stay.id
          }
          }).then( response => {
            this.reminders = response.data['hydra:member']
          })
        } catch (error) {
          this.$root.$emit('error', error)
        } finally {
          this.newReminder = false
        }
      },
      addReminderItem(data){
        if (data) {
          this.reminders.unshift(data)
        }
        return false
      }
    }
  }
</script>
