<!--
  -  Copyright (C) Healabs 2022 - All Rights Reserved
  -  Unauthorized copying of this file, via any medium is strictly prohibited
  -  Proprietary and confidential
  -
  -->

<template>
  <div class="mx-auto">
    <v-dialog v-model="dialog" max-width="550" transition="dialog-bottom-transition">
      <template v-slot:activator="{on , attrs}">
        <v-btn color="primary" elevation="0" small v-bind="attrs" v-on="on">
          Relancer
        </v-btn>
      </template>
      <v-card>
        <v-icon small class="close-submit-dialog" @click="dialog = !dialog">$close</v-icon>
        <v-list class="validate-submit-dialog primary-border">
          <v-list-item-title class="text-h5 font-weight-light primary--text px-5">Relancer</v-list-item-title>
          <v-list-item-subtitle class="text-caption font-alt mb-1 px-5">
            Relancer le patient pour les pièces manquantes au dossier
          </v-list-item-subtitle>
          <form ref="reminder" @submit.prevent="submit">
            <v-expansion-panels flat>
              <v-expansion-panel v-if="missingDocuments.length">
                <v-expansion-panel-header class="px-3 py-1">
                  <v-list-item>
                    <v-img src="@/assets/img/illustrations/reminder-documents.svg" max-width="60px" />
                    <v-list-item-content class="ml-2">
                      <v-list-item-title class="text-subtitle-1 font-weight-light primary--text">
                        Documents
                      </v-list-item-title>
                      <v-list-item-subtitle class="text-body-2 font-alt mb-1">
                        {{ missingDocuments.length }} éléments manquants
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </v-expansion-panel-header>
                <v-divider />
                <v-expansion-panel-content>
                  <v-list-item v-for="(fieldResponse, index) in missingDocuments" :key="index" class="pa-0">
                    <v-list-item-avatar size="16">
                      <font-awesome-icon :icon="['fal', 'file-medical']"
                                         size="2x" class="primary--text"
                      />
                    </v-list-item-avatar>
                    <v-list-item-content>
                      <v-list-item-title class="text-subtitle-1 font-weight-regular op-60">
                        {{ fieldResponse.field.label }}
                      </v-list-item-title>
                      <v-list-item-subtitle class="text-caption font-alt">
                        Documents
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </v-expansion-panel-content>
              </v-expansion-panel>
              <v-expansion-panel v-if="missingAnswer.length">
                <v-expansion-panel-header class="px-3 py-1">
                  <v-list-item>
                    <v-img src="@/assets/img/illustrations/reminder-informations.svg" max-width="60px" />
                    <v-list-item-content class="ml-2">
                      <v-list-item-title class="text-subtitle-1 font-weight-light primary--text">
                        Informations
                      </v-list-item-title>
                      <v-list-item-subtitle class="text-body-2 font-alt mb-1">
                        {{ missingAnswer.length }} éléments manquants
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </v-expansion-panel-header>
                <v-divider />
                <v-expansion-panel-content>
                  <v-list-item v-for="(fieldResponse, index) in missingAnswer" :key="index" class="pa-0">
                    <v-list-item-avatar size="16">
                      <font-awesome-icon :icon="['fal', 'chevron-right']"
                                         size="2x" class="primary--text"
                      />
                    </v-list-item-avatar>
                    <v-list-item-content>
                      <v-list-item-title class="text-subtitle-1 font-weight-regular op-60">
                        {{ fieldResponse.field.label }}
                      </v-list-item-title>
                      <v-list-item-subtitle class="text-caption font-alt">
                        Informations générales
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </v-expansion-panel-content>
              </v-expansion-panel>
              <v-expansion-panel>
                <v-expansion-panel-header class="px-3 py-1">
                  <v-list-item>
                    <v-img src="@/assets/img/illustrations/reminder-comments.svg" max-width="60px" />
                    <v-list-item-content class="ml-2">
                      <v-list-item-title class="text-subtitle-1 font-weight-light primary--text">
                        Commentaire
                      </v-list-item-title>
                      <v-list-item-subtitle class="text-body-2 font-alt mb-1">
                        Ajouter un commentaire
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </v-expansion-panel-header>
                <v-divider />
                <v-expansion-panel-content>
                  <v-textarea v-model="model.comment" outlined dense hide-details="auto" class="mt-3"
                              placeholder="Ajouter un commentaire à la relance" :rules="[$rules.maxLength(255)]"
                  />
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>

            <v-card-actions class="justify-end pb-0">
              <v-btn small text @click="dialog = !dialog">
                Annuler
              </v-btn>
              <v-btn small color="primary" type="submit" :loading="submitting" :disabled="submitting">
                Tout relancer
              </v-btn>
            </v-card-actions>
          </form>
        </v-list>
      </v-card>
    </v-dialog>
    <modal v-model="successDialog" title="Relance envoyée" color="color-dialog validate" subtitle="Une relance a été envoyée avec succès."
           :img="require('@/assets/img/illustrations/complete.svg')" :actions="false"
    />
  </div>
</template>

<script>
  import Modal from '@/modules/core/components/Modal'
  import {create} from '@/modules/forms/api/reminders'

  export default {
    name: 'ReminderForm',
    components: {Modal},
    props: {
      missingSurveyFields: Array,
      stayId: String
    },
    data() {
      return {
        dialog: false,
        submitting: false,
        successDialog: false,
        model: {
          comment: '',
          stay: this.stayId ? this.stayId : ''
        }

      }
    },
    computed: {
      missingDocuments() {
        return this.missingSurveyFields.filter(missingSurveyFields => missingSurveyFields.field.type === 'document')
      },
      missingAnswer() {
        return this.missingSurveyFields.filter(missingSurveyFields => missingSurveyFields.field.type !== 'document')
      }
    },
    methods: {
      async submit() {
        try {
          this.submitting = true
          let response = await create(this.model)
          this.$emit('add-reminder',  response.data)
          this.model.comment = ''
          this.successDialog = true
          this.dialog = false
        } catch (error) {
          this.$root.$emit('error', error)
        } finally {
          this.submitting = false
        }
      }
    }
  }
</script>
