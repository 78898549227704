<!--
  -  Copyright (C) Healabs 2022 - All Rights Reserved
  -  Unauthorized copying of this file, via any medium is strictly prohibited
  -  Proprietary and confidential
  -
  -->

<template>
  <div class="mx-auto">
    <v-dialog :value="value" max-width="550" transition="dialog-bottom-transition"
              @input="value => $emit('input', value)"
    >
      <v-card>
        <v-icon small class="close-submit-dialog" @click="$emit('input', false)">$close</v-icon>
        <v-list class="validate-submit-dialog primary-border">
          <v-list-item-title class="text-h5 font-weight-light primary--text px-5">Modifier la date d'admission</v-list-item-title>

          <v-form ref="form" @submit.prevent="submit">
            <v-col cols="12">
              <date-picker v-model="admissionDate" :max-length="50" label="Date d'admission"
                           :rules="[$rules.required]" :disabled="isCancelled"
              />
            </v-col>

            <v-col cols="12">
              <time-picker v-model="admissionTime" label="Heure d'admission" :rules="[$rules.required]" :disabled="isCancelled" />
            </v-col>

            <v-card-actions class="justify-end pb-0">
              <v-btn small text @click="$emit('input', false)">
                Annuler
              </v-btn>
              <v-btn v-if="!isCancelled" small color="primary" type="submit" :loading="submitting" :disabled="submitting">
                Modifier
              </v-btn>
            </v-card-actions>
          </v-form>
        </v-list>
      </v-card>
    </v-dialog>
  </div>
</template>


<script>
  import DatePicker from '@/modules/core/components/DatePicker'
  import TimePicker from '@/modules/core/components/TimePicker'
  import * as admissions from '@/modules/stays/api/admissions'
  import logger from '@/services/logger'

  export default {
    name: 'StartDateUpdateDialog',
    components: {TimePicker, DatePicker},
    props: {
      value: Boolean,
      stay: Object
    },
    data() {
      let startDate = this.$dayjs(this.stay.startDate)
      return {
        submitting: false,
        admissionDate: startDate.format('YYYY-MM-DD'),
        admissionTime: startDate.format('HH:mm')
      }
    },
    computed: {
      isCancelled() {
        return this.stay.status === 'cancelled'
      }
    },
    methods: {
      async submit() {
        if (this.$refs.form.validate() && !this.isCancelled) {
          try {
            this.submitting = true
            let newStartDate = this.$dayjs(this.admissionDate + ' ' + this.admissionTime).utc().toISOString()

            await admissions.update(this.stay.id, {
              startDate: newStartDate
            }, true)

            this.$root.$emit('snackbar:open', {
              snackbar: () => import('@/modules/core/components/Snackbar'),
              type: 'success',
              title: 'Admission modifiée avec succès.'
            })

            this.$emit('change', newStartDate)
          } catch (error) {
            this.$root.$emit('snackbar:open', {
              snackbar: () => import('@/modules/core/components/Snackbar'),
              type: 'error',
              title: 'Une erreur est survenue'
            })
            logger.error(error)
          } finally {
            this.submitting = false
          }
        }
      }
    }
  }
</script>

<style scoped>

</style>
