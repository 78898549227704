<!--
  -  Copyright (C) Healabs 2022 - All Rights Reserved
  -  Unauthorized copying of this file, via any medium is strictly prohibited
  -  Proprietary and confidential
  -
  -->

<template>
  <v-card class="elevation-x pb-9 pt-1">
    <div class="splitter" />
    <v-card-title class="pb-2 font-alt text-subtitle-1 primary--text">Documents</v-card-title>
    <v-card-subtitle class="font-weight-light text-caption font-alt secondary--text">
      Documents transmis par le patient
    </v-card-subtitle>
    <v-row class="ma-0">
      <template v-if="mediaFieldResponses.length > 0">
        <v-col v-for="(mediaFieldResponse,index) in mediaFieldResponses" :key="index" cols="12" md="4" class="py-2"
               @click.stop="previewMediaFieldResponse = mediaFieldResponse"
        >
          <v-card class="d-flex flex-column align-center document-card c-pointer">
            <!--Object is not clickable, you can only open file by clicking on label-->
            <v-img v-if="mediaFieldResponse.media.mimeType.startsWith('image')" :src="mediaSrc(mediaFieldResponse.media)" height="80px" class="document-img" />
            <div v-else class="d-flex align-center" style="height: 80px;">
              <font-awesome-icon :icon="['fad','file-pdf']" class="red--text" size="2x" />
            </div>
            <v-divider />
            <v-card-title class="font-alt text-caption px-3 document-title w-100 justify-center">
              {{ mediaFieldResponse.field.label }}
            </v-card-title>
          </v-card>
        </v-col>
      </template>
      <template v-else>
        <v-card outlined class="mx-4 px-6 py-6 text-center w-100">
          <span class="text--disabled font-italic">Aucun document disponible</span>
        </v-card>
      </template>
    </v-row>

    <media-preview v-if="previewMediaFieldResponse" :field-response="previewMediaFieldResponse"
                   @close="previewMediaFieldResponse=null"
    />

    <v-card-title class="mt-5 pb-2 font-alt text-subtitle-1 primary--text">Patient</v-card-title>
    <v-card-subtitle class="font-weight-light text-caption font-alt secondary--text">
      Informations générales du patient
    </v-card-subtitle>

    <v-row>
      <v-col cols="12" md="4" class="py-1">
        <v-list-item>
          <v-list-item-content class="py-0">
            <v-list-item-subtitle class="text-caption font-alt">Nom Usuel</v-list-item-subtitle>
            <v-list-item-title :class="displayValue(patient.lastName)">
              {{ patient.lastName || 'Non renseigné' }}
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-col>
      <v-col cols="12" md="4" class="py-1">
        <v-list-item>
          <v-list-item-content class="py-0">
            <v-list-item-subtitle class="text-caption font-alt">Nom de naissance</v-list-item-subtitle>
            <v-list-item-title :class="displayValue(patient.birthName)">
              {{ patient.birthName || 'Non renseigné' }}
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-col>
      <v-col cols="12" md="4" class="py-1">
        <v-list-item>
          <v-list-item-content class="py-0">
            <v-list-item-subtitle class="text-caption font-alt">Prénom</v-list-item-subtitle>
            <v-list-item-title :class="displayValue(patient.firstName)">
              {{ patient.firstName || 'Non renseigné' }}
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-col>
      <v-col cols="12" md="4" class="py-1">
        <v-list-item>
          <v-list-item-content class="py-0">
            <v-list-item-subtitle class="text-caption font-alt">Sexe</v-list-item-subtitle>
            <v-list-item-title :class="displayValue(patient.gender)">
              {{ reformatSexValue(patient.gender) || 'Non renseigné' }}
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-col>
      <v-col cols="12" md="4" class="py-1">
        <v-list-item>
          <v-list-item-content class="py-0">
            <v-list-item-subtitle class="text-caption font-alt">Date de naissance</v-list-item-subtitle>
            <v-list-item-title>{{ $dayjs(patient.birthDate).format('DD MMMM YYYY') }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-col>
    </v-row>
    <v-card-title class="mt-5 pb-2 font-alt text-subtitle-1 primary--text">Contact</v-card-title>
    <v-card-subtitle class="font-weight-light text-caption font-alt secondary--text">
      Informations de contact du patient
    </v-card-subtitle>
    <v-row>
      <v-col cols="12" md="6" class="py-1">
        <v-list-item>
          <v-list-item-content class="py-0">
            <v-list-item-subtitle class="text-caption font-alt">Téléphone fixe</v-list-item-subtitle>
            <v-list-item-title :class="displayValue(patient.phone)">
              {{ patient.phone || 'Non renseigné' }}
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-col>
      <v-col cols="12" md="6" class="py-1">
        <v-list-item>
          <v-list-item-content class="py-0">
            <v-list-item-subtitle class="text-caption font-alt">Téléphone mobile</v-list-item-subtitle>
            <v-list-item-title :class="displayValue(patient.mobilePhone)">
              {{ patient.mobilePhone || 'Non renseigné' }}
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-col>
      <v-col cols="12" md="6" class="py-1">
        <v-list-item>
          <v-list-item-content class="py-0">
            <v-list-item-subtitle class="text-caption font-alt">E-mail</v-list-item-subtitle>
            <v-list-item-title :class="displayValue(patient.email)">
              {{ patient.email || 'Non renseigné' }}
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-col>
      <v-col cols="12" class="py-1">
        <v-list-item>
          <v-list-item-content v-if="patient.address" class="py-0">
            <v-list-item-subtitle class="text-caption font-alt">Adresse</v-list-item-subtitle>
            <v-list-item-title :class="displayValue(patient.address)">
              {{ patient.address ? addressDisplayer(patient.address) : 'Non renseigné' }}
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-col>
    </v-row>
    <template v-for="fieldSet in sortedFieldsets(surveyFieldSets)">
      <div :key="fieldSet['@id']" class="mt-5">
        <v-card-title class="pb-2 font-alt text-subtitle-1 primary--text">{{ fieldSet.title }}</v-card-title>
        <v-card-subtitle class="font-weight-light text-caption font-alt secondary--text">
          {{ fieldSet.subtitle }}
        </v-card-subtitle>
        <v-row>
          <v-col v-for="field in fields(fieldSet)" :key="field['@id']" md="6" class="py-1">
            <v-list-item>
              <v-list-item-content class="py-0">
                <v-list-item-subtitle class="text-caption font-alt">{{ field.label }}</v-list-item-subtitle>
                <v-list-item-title :class="displayValue(fieldResponseValue(field))">
                  {{ reformatValue(fieldResponseValue(field)) || 'Non renseigné' }}
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-col>
        </v-row>
      </div>
    </template>
  </v-card>
</template>


<script>
  import MediaPreview from './MediaPreview'
  import dataDisplayers from '@/mixins/dataDisplayers'
  import config from '@/config'

  export default {
    name: 'Informations',
    components: {MediaPreview},
    mixins: [dataDisplayers],
    props: {
      patient: Object,
      form: Object,
      fieldResponses: Array
    },
    data() {
      return {
        previewMediaFieldResponse: null
      }
    },
    computed: {
      sortedFieldsets(){
        return fieldSets => fieldSets.slice().sort(function(a,b){
          return a.position - b.position
        })
      },
      fields() {
        return fieldSet => fieldSet.fields.filter(field => field.type).sort(function(a,b){
          return a.position - b.position
        })
      },
      fieldResponseValue() {
        return field => {
          let fieldResponse = this.fieldResponses ? this.fieldResponses.find(fieldResponse => fieldResponse.field['@id'] === field['@id']) : null
          return fieldResponse ? fieldResponse.response : null
        }
      },
      mediaSrc() {
        return media => config.urls.api + media['@id'] + '.binary'
      },
      mediaFieldResponses() {
        return this.fieldResponses ? this.fieldResponses.filter(fieldResponse => fieldResponse.field.type === 'document' && fieldResponse.media) : []
      },
      surveyFieldSets() {
        return this.form ? this.form.fieldSets.filter(fieldSet => fieldSet.fields.find(field => 'document' !== field.type)) : []
      }
    },
    methods: {
      displayValue(entry) {
        return entry ? '' : 'font-italic text--disabled'
      },
      /**
       * KILL ME
       * @param value
       * @returns {string|*}
       */
      reformatValue(value) {
        if (value === 'true') {
          return 'oui'
        } else if (value === 'false') {
          return 'non'
        } else {
          return value
        }
      },
      /**
       * @param value
       * @returns {string|*}
       */
      reformatSexValue(value) {
        if (value === 'M') {
          return 'Homme'
        } else if (value === 'F') {
          return 'Femme'
        } else {
          return value
        }
      }
    }
  }
</script>
<style lang="scss" scoped>
  .document-card {
    .document-img {
      filter: blur(4px);
    }

    .document-title {
      border-top: 1px solid #ddd;
    }
  }
</style>
